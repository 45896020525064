// author:马云阳；description:众惠-公募-填写转换信息2
<template>
<section class="PublicFundProcess">
  <!-- <NavRightSide/> -->
  <div class="publicfundprocess_cont">
    <a @click="topages" class="title_">
      <span class="iconfont">&#xe665;</span> 基金定投
    </a>
    <div class="side_cont">
      <ProgressBar :sideData="side" />
    </div>
    <div class="solids_"></div>
    <div class="card_">
      <img src="@/static/img/my/PasswordAlterSuccess.png" class="true_" alt="">
      <div class="true_ti">
        <div class="tis_">计划变更成功</div>
        <div class="timers_">{{newdates||'--'}}</div>
      </div>
      <div class="cards_tis">
        定投计划预计将于<span>{{queryParams.nextFixrequestDate}} 15:00</span>生效,生效之前将按原计划执行
        
      </div>
      <div class="card_timers">
        <div class="l_ti">定投基金</div>
        <div class="r_timers">{{queryParams.fundName}}</div>
      </div>
      <div class="card_timers">
        <div class="l_ti">买入金额</div>
        <div class="r_timers">银行卡支付</div>
      </div>
      <div class="card_timers">
        <div class="l_ti">付款方式</div>
        <div class="r_timers" style="color:#25293D;font-weight:400;"><img style="width:28px;height:28px;" src="@/static/img/my/bank_icon.png" alt="">{{queryParams.bankname}}<span style="color:#A5A5A5;">({{banknum}})</span></div>
      </div>
      <div class="card_timers_">
        <div class="l_ti">下次扣款</div>
        <div class="r_timers">
          <div style=" align-items: unset;" class="top_">
            <div class="imgs_ img_rudis">
              <!-- <img src="" alt=""> -->
            </div>
            <div class="top_name_">
              {{queryParams.nextFixrequestDate}} {{xq}}<br /><span style="font-size: 18px;color: #25293d;font-weight: 300;">下次扣款金额{{queryParams.balance||0}}元</span>
              <!-- <span>161725</span> -->
            </div>
          </div>
          <!-- <div class="center_">
                     <div class="imgs_">
                      <span style="color:#CE9B63" class="iconfont icon-a-mine-roolout"></span>
                    </div>
                  </div> -->
        </div>
      </div>
      <a @click="topages" class="topage_">
        查看我的定投
      </a>
    </div>

  </div>
</section>
</template>

<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "AddTrueFour",
  components: {
    ProgressBar
  },
  data() {
    return {
      queryParams: {},
      side: [
        {
          title: "修改定投信息",
          type: 1
        },
        {
          title: "输入交易密码",
          type: 1
        },
        {
          title: "操作结果",
          type: 2
        }
      ],
      newdates:'--',
      xq:'--'
    };
  },
  computed: {
    banknum() {
      return this.queryParams.bankAccount.slice(-4);
    }
  },
  created() {
    this.initParams();
  },
  methods: {
    initParams() {
      this.queryParams = this.$route.params.obj;
      this.getnewdata();
      this.getweed(this.queryParams.nextFixrequestDate);

    },
    topages() {
      this.$router.push({ name: "FundSetFing" });
    },

    getnewdata() {
      let date = new Date();
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let jjj = yy + "-" + mm + "-" + dd + " " + "15:00:00";
      // let aaa = new Date(jjj);
      this.newdates = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      // let bbb = new Date(this.newdates);
      // if (bbb >= aaa) {
      //   this.txts = "明天15点";
      //   let dds = date.getDate() + 2;
      //   let ees = date.getDate() + 3;
      //   this.Ns = yy + "-" + mm + "-" + dds;
      //   this.Es = yy + "-" + mm + "-" + ees;
      // } else {
      //   this.txts = "15点";
      //   let dds = date.getDate() + 1;
      //   let ees = date.getDate() + 2;
      //   this.Ns = yy + "-" + mm + "-" + dds;
      //   this.Es = yy + "-" + mm + "-" + ees;
      // }
    },
    getweed(bs) {
      let a=new Date(bs);
      // let yy = a.getFullYear();
      // let mm = a.getMonth() + 1;
      // let dd = a.getDate();

      let weekDay = [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ];
      this.xq = weekDay[a.getDay()];
    },
    topeges() {
      this.$router.go(-3);
    }
  }
};
</script>

<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  color: #bdc0cb;
}

::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  padding: 0px 0px 50px 0;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;

  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 1200px;

    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }

    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }

    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }

    .card_ {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .true_ {
        width: 120px;
        height: 120px;
        margin-bottom: 30px;
      }

      .true_ti {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        .tis_ {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #25293d;
          text-align: center;
        }

        .timers_ {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #a5a5a5;
          margin-top: 14px;
        }
      }

      .cards_tis {
        width: 646px;
        height: 64px;
        border-radius: 2px;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-content: center;
        text-align: center;
        line-height: 64px;
        color: #25293D;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          text-align: center;
        }
      }

      .card_timers {
        margin-top: 20px;
        display: flex;
        width: 100%;
        align-items: center;

        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }

        .r_timers {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          text-align: left;
          color: #25293d;
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
          }
        }
      }

      .card_timers_ {
        margin-top: 24px;
        display: flex;
        width: 100%;

        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }

        .r_timers {
          .top_ {
            display: flex;
            align-items: center;

            // .imgs_ {
            //   width: 28px;
            //   height: 28px;
            //   img {
            //     width: 100%;
            //     height: 100%;
            //   }
            // }
            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }

            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;

              span {
                color: #a5a5a5;
              }
            }
          }

          .center_ {
            display: flex;
            margin-top: 30px;

            // align-items: center;
            .imgs_ {
              width: 28px;
              height: 28px;
              text-align: center;

              img {
                width: 7px;
                height: 25px;
              }
            }

            .top_name_ {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #a5a5a5;
              margin-left: 8px;

              span {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #e45247;
              }
            }
          }

          .footer_ {
            display: flex;
            align-items: center;
            margin-top: 30px;

            .imgs_ {
              width: 28px;
              height: 28px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }

            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;

              span {
                color: #a5a5a5;
              }
            }
          }
        }
      }

      .topage_ {
        display: inline-block;
        width: 240px;
        height: 48px;
        background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #b88141;
        margin-top: 60px;
      }
    }
  }
}
</style>
